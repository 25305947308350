import './App.css';
import React from 'react';
import { themeOptions } from './Theme';
import {
  ThemeProvider, Container, Box,
} from '@mui/material';
import Background from './images/freepik_backdrop.jpg';
import About from './sections/About';
import {Header, Footer} from './components/Banners';


const contentStyle = {
  background: "#ffffffd6",
  borderRadius: "5px 1rem 3rem 5px",
  borderLeft: "#ec9742 1rem solid",
  boxShadow: "rgb(8 8 9 / 42%) 0px 0.5px 3px",
  color: "black",
  fontSize: "2rem",
  textShadow: "none",
  minHeight: "40svh",
  padding: "1rem",
  margin: "2rem 0 2rem 0"
}

const backgroundTheme = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 110%",
  backgroundImage: `url(${Background})`,
  animationName: "scrolling",
  animationDuration: "360s",
  animationIterationCount: "infinite",
}

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <Header/>
      <Box style={backgroundTheme} sx={{ minHeight: "80svh" }}>
        <br />
        <br />
        <Container maxWidth={"80vw"}>
            <div style={contentStyle}>
              <About />
            </div>
            <br />
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default App;


