import { createTheme } from "@mui/material";

export const themeOptions = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#ec9742',
      },
      secondary: {
        main: '#9c27b0',
      },
      text: {
        primary: 'rgba(22,21,21,0.87)',
        secondary: 'rgba(20,18,18,0.6)',
        disabled: 'rgba(57,54,54,0.38)',
        hint: '#322767',
      },
      divider: '#ec9742',
      info: {
        main: '#d1c702',
      },
    },
  });