import {
    Typography,
  } from '@mui/material';

const Header = () => (
    <div className="Header">
        <Typography variant="overline" fontSize={"2rem"} lineHeight={"1"}><b><u>F</u></b>orest <b><u>T</u></b>iger <b><u>T</u></b>echnology</Typography><br />
      </div>
)

const Footer = () => (
    <div className="Footer">
        <div>© Forest-Tiger-Technology {new Date().getFullYear()}</div>
        <div style={{margin: "1rem"}}></div>
        <div> Website by Forest Tiger Technology <br/> Background originally by Freepik</div>
    </div>
)


export {Header, Footer};