import {
    Container, Typography,
    Grid, Avatar, Badge
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const About = () => (
    <Container maxWidth="xl">
        <Container maxWidth="xl" >
            <Grid container spacing={2}>
                <Grid item sm={12} md={4} lg={3} xl={3}>
                    <br/>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        badgeContent={
                            <Typography variant='caption' style={{background: "white", padding: "0.2rem 0.5rem", borderRadius: "0.5rem", boxShadow: "1px 1px 6px #080809"}}><b>Director</b></Typography>
                        }>
                        <Avatar
                            alt="Luke S"
                            src="/Bio_Image.jpeg"
                            sx={{ width: 200, height: 200 }} />
                    </Badge>
                </Grid>
                <Grid item sm={12} md={6} lg={9} xl={9} >
                    <Typography variant="caption" fontSize={"1.1rem"}><b>Hi, I'm Luke!</b></Typography>
                    <br /><br />
                    <Typography>I'm a freelance educator.</Typography>
                    <br />
                    <Typography>Trading as Forest Tiger Technology I offer teaching and mentoring as a service. At present I am a member of Code First Girls Technical Advisory Board and primarily deliver my services as a Senior Lead Instructor for the Software Cohort on the CFG Degree.</Typography>
                    <br/>
                    <Typography>I work full-time alongside my endeavours as Forest Tiger Technology and as such have been a programmer within both public and private sectors of the UK Software Industry since 2017. I've worked on election management systems, business-to-business sales platforms, wholesale stock applications, document management products, and many more. </Typography>
                    <br/>
                    <Typography>Through my career so far I've found myself specialising in C#, .NET, various JavaScript frameworks, database systems like MySQL and MongoDB, as well as suppliers in the Cloud such as AWS and Azure. Forever changing as the requirements require!</Typography>
                    <br />
                    <Typography>More recently I've been working as a Scrum Master and Delivery Manager. This has meant less programming on a day-to-day basis and more planning and consideration for the wider teams I work with.</Typography>
                    <br />
                    <Typography>Recreationally you'll find me making games for code jams, creating throw away products in new and exciting technologies, and helping up and coming programmers break into the industry. If you're interested in seeing an example of the imaginative things I've created in my spare time, feel free to take a look at Stranded below...</Typography>
                    <br/>
                    <iframe title='Stranded by Bizzle_Dapp on Itch.io' frameborder="0" src="https://itch.io/embed/1186767?linkback=true&amp;border_width=2&amp;link_color=fab35b&amp;border_color=fab35b" width="100%" height="169"><a href="https://bizzle-dapp.itch.io/stranded">Stranded by Bizzle_Dapp</a></iframe>
                    <br/>
                    <br/>
                    <Typography>To contact me about prospective offers: <a href="mailto:luke@forest-tiger-technology.co.uk" style={{
                display: "flex",
                alignItems: "center",
            }}><EmailIcon />luke@forest-tiger-technology.co.uk</a></Typography>
                </Grid>
            </Grid>
        </Container>
    </Container>
)

export default About;